<template>
  <el-dialog
    :title="$t('device.status')"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="60%"
    top="5vh"
    @close="onDialogClose">
    <div v-loading="contentLoading" class="body">
      <el-form
        ref="formValidate1"
        class="form"
        :label-width="$l('device.labelWidth','100px')"
        :model="info"
        :rules="ruleValidate1">
        <div class="vm-separate">
          <el-form-item label="ICCID">
            {{info.iccid}}
          </el-form-item>
          <el-form-item :label="$t('device.signalIntensity')">
            {{info.dbm}}
          </el-form-item>
        </div>
        <div class="vm-separate">
          <el-form-item :label="$t('device.softwareVersion')">
            {{info.softwareVersion}}
          </el-form-item>
          <el-form-item :label="$t('device.hardwareVersion')">
            {{info.hardwareVersion}}
          </el-form-item>
        </div>
        <div class="vm-separate">
          <el-form-item label="IP" prop="ip">
            <el-input v-model="info.ip" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
          <div></div>
        </div>
        <div class="vm-separate">
          <el-form-item :label="$t('device.port')" prop="port">
            <el-input v-model="info.port" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
          <div>
            <el-button
              type="primary"
              style="margin-left: 20px;"
              :loading="submitLoading"
              :disabled="saveDisabled"
              @click="handleSubmit1">
              {{$t("device.setUp")}}
            </el-button>
          </div>
        </div>
      </el-form>
      <el-form
        ref="formValidate2"
        class="form"
        :label-width="$l('device.labelWidth','100px')"
        :model="info"
        :rules="ruleValidate2">
        <div class="vm-separate">
          <el-form-item :label="$t('device.APNName')" prop="apnName">
            <el-input v-model="info.apnName" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
          <div></div>
        </div>
        <div class="vm-separate">
          <el-form-item :label="$t('device.userName')" prop="userName">
            <el-input v-model="info.apnUserName" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
          <div></div>
        </div>
        <div class="vm-separate">
          <el-form-item :label="$t('device.password')" prop="password">
            <el-input v-model="info.apnPassword" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
          <div>
            <el-button
              type="primary"
              style="margin-left: 20px;"
              :loading="submitLoading"
              :disabled="saveDisabled"
              @click="handleSubmit2">
              {{$t("device.setUp")}}
            </el-button>
          </div>
        </div>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
  export default {
    data() {
      return {
        submitLoading: false,
        saveDisabled: false,
        dialogVisible: false,
        contentLoading: false,
        dtuCode: "",
        nodeNo: "01",
        info: {
          dtuCode: "",
          nodeNo: "",
          iccid: "",
          softwareVersion: "",
          hardwareVersion: "",
          electricityQuantity: "",
          temperature: "",
          dbm: "",
          ip: "",
          port: "",
          apnName: "",
          apnUserName: "",
          apnPassword: "",
        },
        ruleValidate1: {
          ip: [
            {required: true, message: "IP" + this.$t("common.tip.notNull"), trigger: "blur"},
          ],
          port: [
            {required: true, message: this.$t("device.port") + this.$t("common.tip.notNull"), trigger: "blur"},
          ],
        },
        ruleValidate2: {
          apnName: [
            {required: true, message: this.$t("device.APNName") + this.$t("common.tip.notNull"), trigger: "blur"},
          ],
        },
      };
    },
    methods: {
      open(dtuCode) {
        this.dialogVisible = true;
        if (dtuCode.length > 0) {
          this.dtuCode = dtuCode;
          this.nodeNo = "01";
          this.info.dtuCode = this.dtuCode;
          this.info.nodeNo = this.nodeNo;
          this.getData();
        }
      },
      getData() {
        this.contentLoading = true;
        // this.saveDisabled = true;
        let params = {
          dtuCode: this.info.dtuCode,
        };
        this.$api.getData("devices/deviceInfo", params).then(res => {
          this.info = Object.assign(this.info, res.data);
          this.info.dtuCode = this.dtuCode;
          this.info.nodeNo = this.nodeNo;
          console.log(this.info);
          this.contentLoading = false;
          this.saveDisabled = false;
        }).catch(() => {
          this.contentLoading = false;
        });
      },
      handleSubmit1() {
        if (this.info.dtuCode.length === 0 || this.info.nodeNo.length === 0) {
          this.$message.error("DTU和节点不能为空");
          return;
        }
        this.$refs.formValidate1.validate((valid, item) => {
          if (valid) {
            this.submitLoading = true;
            this.$api.save("devices/severInfo", this.info).then(res => {
              this.submitLoading = false;
              this.$message.success(this.$t("common.tip.saveSuccess"));
            }).catch(error => {
              this.submitLoading = false;
              this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
            });
          }
        });
      },
      handleSubmit2() {
        if (this.info.dtuCode.length === 0 || this.info.nodeNo.length === 0) {
          this.$message.error("DTU和节点不能为空");
          return;
        }
        this.$refs.formValidate2.validate((valid, item) => {
          if (valid) {
            this.submitLoading = true;
            this.$api.save("devices/APN", this.info).then(res => {
              this.submitLoading = false;
              this.$message.success(this.$t("common.tip.saveSuccess"));
            }).catch(error => {
              this.submitLoading = false;
              this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
            });
          }
        });
      },
      onDialogClose() {
        this.$refs.formValidate1.resetFields();
        this.$refs.formValidate2.resetFields();
        this.dtuCode = "";
        this.nodeNo = "";
        this.info = {
          dtuCode: "",
          nodeNo: "",
          iccid: "",
          softwareVersion: "",
          hardwareVersion: "",
          electricityQuantity: "",
          temperature: "",
          dbm: "",
          ip: "",
          port: "",
          apnName: "",
          apnUserName: "",
          apnPassword: "",
        };
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
